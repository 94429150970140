import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import {
  Layout,
  Seo,
  Cta,
  TwoTextColumns,
  TextBlocks,
  HeroWithImageBlock,
  Banner,
} from "../../index.js"
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary"
import MindBodyPage from "../components/MindBody/MindBodyPage"

const LocationLandingThaoDien = ({ data, pageContext }) => {
  let { heroTitle, heroSubTitle, heroImage } = data.location.blocks[0].heroBlock
  let slider = data.location.blocks[1].sliderBlock.sliderContainer
  let cta = data.location.blocks[2].ctaBlock

  let textColumn = data.location.blocks[3].repeatTextBlock?.blockContent

  let { textBlockTitle, textBlockSubTitle, textCard } =
    data.location.blocks[4].textBlock
  let lang = pageContext.slug
  const mindBodyForm = useRef()
  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  return (
    <Layout>
      <Seo
        title={data.location.seo?.title}
        description={data.location.seo?.metaDesc}
        featuredImage={
          data.location.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />
      <Banner onClick={onClick} hidden={toggle} />
      <div ref={mindBodyForm}></div>
      <HeroWithImageBlock
        heroImage={heroImage}
        heroTitle={{ normalTitle: heroTitle, strokeTitle: heroSubTitle }}
        isSlider={true}
        slider={slider}
        className="location-hero-customize"
        idMindBody="thaodien-mindbody"
      />

      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}

      <Cta
        cta={cta}
        bgColor="#914cff"
        textColor="#fff"
        className="location-cta-customize"
        onClick={onClick}
      />
      <TwoTextColumns
        column1={{
          title: textColumn[0].mainContent,
          text: textColumn[0].subContent,
          buttonText: textColumn[0].buttonText,
          buttonLink: textColumn[0].buttonLink.uri,
        }}
        column2={{
          title: textColumn[1].mainContent,
          text: textColumn[1].subContent,
          buttonText: textColumn[1].buttonText,
          buttonLink: textColumn[1].buttonLink.uri,
        }}
      />
      <TextBlocks
        TextBlock={2}
        textBlockTitle={textBlockTitle}
        textBlockSubTitle={textBlockSubTitle}
        textCard={textCard}
      />
    </Layout>
  )
}
export default LocationLandingThaoDien

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    location: wpPage(slug: { eq: $slug }, language: { slug: { eq: $lang } }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfSliderBlock {
          sliderBlock {
            sliderContainer {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, fit: COVER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              title
              strokeTitle
              content
            }
          }
        }
        ... on WpAcfRepeatTextBlock {
          repeatTextBlock {
            blockContent {
              mainContent
              subContent
              buttonText
              buttonLink {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
        ... on WpAcfTextBlock {
          textBlock {
            textBlockSubTitle
            textBlockTitle
            textCard {
              textCardContent
              textCardTitle
              textCardImage {
                sourceUrl
              }
            }
          }
        }
        ... on WpAcfCtaBlock {
          ctaBlock {
            ctaTitle
            ctaStrokeTitle
            ctaButton {
              target
              title
              url
            }
          }
        }
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
  }
`
